import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./adminReports.css";
import GoBackButton from "../../../components/go-back-button/GoBackButton.jsx";
import TotalBalance from "../../../components/total-balance/TotalBalance.jsx";
import { utils } from "../../../utils/utils.jsx";
import { IoIosArrowForward } from "react-icons/io";
import { MdOutlineAnalytics } from "react-icons/md";
import { BiSolidReport } from "react-icons/bi";
import CompanyDailyStatistics from "../../../components/reports/company-daily-statistics/CompanyDailyStatistics.jsx";
import SalesAgent from "../../../components/reports/sales-agent/SalesAgent.jsx";

class AdminReports extends Component {
  state = {
    width: window.innerWidth,
    account: {},

    stage: 1,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    console.log("this.props.history", this.props.history);
    if (this.props.phoneVerified != null && !this.props.phoneVerified) {
      utils.goToURL(this.props, "/dashboard/settings/verify-phone");
    } else if (this.props.emailVerified != null && !this.props.emailVerified) {
      utils.goToURL(this.props, "/dashboard/settings/verify-email");
    } else if (this.props.idVerified != null && !this.props.idVerified) {
      utils.goToURL(this.props, "/dashboard/settings/id-verification");
    }

    utils.checkWindowWidth(
      this.setState.bind(this),
      this.props.changeGoBackNavBar.bind(this),
      true,
      this.tryToGoBackStage
    );
    window.addEventListener(
      "resize",
      utils.checkWindowWidth.bind(
        this,
        this.setState.bind(this),
        this.props.changeGoBackNavBar.bind(this),
        true,
        this.tryToGoBackStage
      )
    );

    if (this.props.account) {
      this.setState({
        account: this.props.account,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props != prevProps) {
      if (this.props.account) {
        this.setState({
          account: this.props.account,
        });

        if (this.props.phoneVerified != null && !this.props.phoneVerified) {
          utils.goToURL(this.props, "/dashboard/settings/verify-phone");
        } else if (
          this.props.emailVerified != null &&
          !this.props.emailVerified
        ) {
          utils.goToURL(this.props, "/dashboard/settings/verify-email");
        } else if (this.props.idVerified != null && !this.props.idVerified) {
          utils.goToURL(this.props, "/dashboard/settings/id-verification");
        }
      }
    }
  }

  moveStage = (stage) => {
    this.setState({
      stage: stage,
    });
  };

  goBackStage = () => {
    this.setState({
      stage: 1,
    });
  };

  tryToGoBackStage = () => {
    if (this.state.stage == 1) {
      utils.goToURL(this.props, "/dashboard");
    } else {
      this.goBackStage();
    }
  };

  goToResultStage = (resultSuccess, resultMessage) => {
    this.setState({
      resultSuccess: resultSuccess,
      resultMessage: resultMessage,
      stage: "result",
    });
  };

  copyText = () => {
    this.setState({
      inboundAddressCopied: true,
    });
    navigator.clipboard.writeText(this.state.inboundAddress);
  };

  handleDepositCloseModal = () => {
    this.setState({
      showDepositModal: false,
    });
  };

  getWidthForModal = () => {
    if (this.state.width > 1200) {
      return "600";
    } else if (this.state.width < 1200 && this.state.width > 820) {
      return this.state.width * 0.8;
    } else {
      return this.state.width * 0.9;
    }
  };

  getHeightForModal = () => {
    if (window.innerHeight > 820) {
      return window.innerHeight * 0.55;
    } else {
      return window.innerHeight * 0.65;
    }
  };

  setCurrency = (value) => {
    this.setState({
      currency: value,
    });
  };

  render() {
    return (
      <React.Fragment>
        <div class="reports-container">
          <div class="reports-body">
            <div class="side-container">
              <GoBackButton
                region={this.props.region}
                localizedText={this.props.localizedText}
                goBack={this.tryToGoBackStage}
              ></GoBackButton>
            </div>
            <div class="middle-container container-padding">
              {this.state.stage == 1 ||
              this.state.stage == 2 ||
              this.state.stage == 3 ? (
                <div>
                  {" "}
                  <TotalBalance
                    account={this.props.account}
                    region={this.props.region}
                    localizedText={this.props.localizedText}
                    token={this.props.token}
                  ></TotalBalance>
                  <div class="title-div fade-in-up">
                    <div class="title">
                      {this.props.localizedText["reports"]}
                    </div>
                  </div>
                  {this.state.stage == 1 ? (
                    <div class="section">
                      <div class="option-div">
                        <div class="reports-list">
                          <div
                            className="reports-row-container credit-card-row-container content-container"
                            onClick={this.moveStage.bind(this, 2)}
                          >
                            <div className="metadata-column">
                              <MdOutlineAnalytics class="icon" />
                              <div className="text">
                                <div className="option-name">
                                  {
                                    this.props.localizedText[
                                      "company-daily-statistics"
                                    ]
                                  }
                                </div>
                                <div className="option-description">
                                  {
                                    this.props.localizedText[
                                      "generate-and-download-daily-statistical-reports"
                                    ]
                                  }
                                </div>
                              </div>
                            </div>
                            <IoIosArrowForward class="arrow"></IoIosArrowForward>
                          </div>

                          <div
                            className="reports-row-container credit-card-row-container content-container"
                            onClick={this.moveStage.bind(this, 3)}
                          >
                            <div className="metadata-column">
                              <BiSolidReport class="icon" />
                              <div className="text">
                                <div className="option-name">
                                  {" "}
                                  {this.props.localizedText["sales-agent"]}
                                </div>
                                <div className="option-description">
                                  {
                                    this.props.localizedText[
                                      "generate-sales-agent-report"
                                    ]
                                  }
                                </div>
                              </div>
                            </div>
                            <IoIosArrowForward class="arrow"></IoIosArrowForward>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {this.state.stage == 2 ? (
                    <CompanyDailyStatistics
                      changeGoBackNavBar={this.props.changeGoBackNavBar}
                      localizedText={this.props.localizedText}
                      tryToGoBackStage={this.tryToGoBackStage}
                      setCurrency={this.setCurrency}
                      goToResultStage={this.goToResultStage}
                      token={this.props.token}
                      account={this.props.account}
                    />
                  ) : null}
                  {this.state.stage == 3 ? (
                    <SalesAgent
                      changeGoBackNavBar={this.props.changeGoBackNavBar}
                      localizedText={this.props.localizedText}
                      tryToGoBackStage={this.tryToGoBackStage}
                      setCurrency={this.setCurrency}
                      goToResultStage={this.goToResultStage}
                      token={this.props.token}
                      account={this.props.account}
                    />
                  ) : null}
                </div>
              ) : null}{" "}
            </div>
            <div class="side-container"></div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(AdminReports);
