import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./salesAgent.css";
import SalesAgentService from "./SalesAgentService.jsx";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { utils } from "../../../utils/utils.jsx";
import { Spinner } from "react-bootstrap";
import ErrorMessageComponent from "../../errorMessage/ErrorMessage.jsx";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCheck, FaCalendar } from "react-icons/fa6";
import { MdOutlinePictureAsPdf, MdOutlineError } from "react-icons/md";
import InputGroup from "react-bootstrap/InputGroup";

class SalesAgent extends Component {
  state = {
    width: window.innerWidth,

    listSalesAgent: [],

    salesAgent: "",
    month: "",
    year: "",
    htmlData: "",

    loadingSalesAgent: false,
    loadingGenerate: false,
    loadingDownload: false,
    pdfGeneratedSuccessfully: null,
    pdfGenerated: {},
  };

  service = new SalesAgentService();

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    utils.checkWindowWidth(
      this.setState.bind(this),
      this.props.changeGoBackNavBar.bind(this),
      true,
      this.props.tryToGoBackStage
    );
    window.addEventListener(
      "resize",
      utils.checkWindowWidth.bind(
        this,
        this.setState.bind(this),
        this.props.changeGoBackNavBar.bind(this),
        true,
        this.props.tryToGoBackStage
      )
    );

    this.service.getSalesAgents(this.props.token, this.setState.bind(this));
  }

  componentDidUpdate(prevProps, prevState) {}

  setMessages = (type) => {
    this.setState({
      errorMessageType: type,
    });
  };

  handleChange = (event, setState) => {
    const target = event.target;
    const value = target.value;

    setState(
      {
        salesAgent: value,
      },
      () => {
        if (this.state.salesAgent && this.state.month && this.state.year) {
          this.setState(
            {
              loadingGenerate: true,
              loadingDownload: false,
              pdfGeneratedSuccessfully: null,
            },
            () => {
              this.service.generateSalesAgentReport(
                this.props.token,
                this.state.month,
                this.state.year,
                this.state.salesAgent,
                "HTML",
                this.setState.bind(this),
                this.setMessages.bind(this),
                this.downloadPDF.bind(this)
              );
            }
          );
        }
      }
    );
  };

  setDate = (date) => {
    this.setState(
      {
        date: utils.formatDateUS(date),
        year: date.getYear() + 1900,
        month: date.getMonth(),
      },
      () => {
        if (this.state.salesAgent && this.state.month && this.state.year) {
          this.setState(
            {
              loadingGenerate: true,
              loadingDownload: false,
              pdfGeneratedSuccessfully: null,
            },
            () => {
              this.service.generateSalesAgentReport(
                this.props.token,
                this.state.month,
                this.state.year,
                this.state.salesAgent,
                "HTML",
                this.setState.bind(this),
                this.setMessages.bind(this),
                this.downloadPDF.bind(this)
              );
            }
          );
        }
      }
    );
  };

  generateSalesAgentReport = () => {
    this.setState(
      {
        loadingDownload: true,
        pdfGeneratedSuccessfully: null,
        pdfGenerated: {},
      },
      () => {
        this.service.generateSalesAgentReport(
          this.props.token,
          this.state.month,
          this.state.year,
          this.state.salesAgent,
          "PDF",
          this.setState.bind(this),
          this.setMessages.bind(this),
          this.downloadPDF.bind(this)
        );
      }
    );
  };

  downloadPDF = (data, name) => {
    utils.generatePDF(name, data, this.setState.bind(this));
  };

  render() {
    return (
      <React.Fragment>
        {this.state.loadingSalesAgent ? (
          <div class="fade-in-up">
            <Spinner className="loader loading-currencies" />
          </div>
        ) : (
          <div class="sales-agent-div">
            <div class="section">
              <div class="left-input content-container">
                {" "}
                <Form.Group className="mb-3">
                  <Form.Label className={"input-label-inside-div"}>
                    {this.props.localizedText["sales-agent"]}
                  </Form.Label>
                  <InputGroup className="mb-3 input-field">
                    <Form.Select
                      variant="outline-secondary"
                      name="salesAgent"
                      onChange={(e) =>
                        this.handleChange(e, this.setState.bind(this))
                      }
                      style={{
                        width: "100%",
                        flex: "0 1 auto",
                        textAlign: "start",
                      }}
                      disabled={this.state.loadingSent}
                    >
                      <option></option>
                      {this.state.listSalesAgent.map((salesAgent) => (
                        <option value={salesAgent.id}>
                          {salesAgent.email}
                        </option>
                      ))}
                    </Form.Select>
                  </InputGroup>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="input-label-inside-div">
                    {this.props.localizedText["date"]}
                  </Form.Label>
                  <DatePicker
                    className={
                      this.state.loadingDownload
                        ? "date-picker-report disabled"
                        : "date-picker-report"
                    }
                    showIcon
                    onChange={(date) => this.setDate(date)}
                    selected={Date.parse(this.state.date)}
                    icon={<FaCalendar className="calendar-icon" />}
                    disabled={this.state.loadingDownload}
                    showMonthYearPicker
                    renderMonthContent={this.renderMonthContent}
                    dateFormat="MM/yyyy"
                  />
                </Form.Group>
                <Button
                  className="inside-primary-button generate-pdf-buton"
                  disabled={
                    this.state.loadingDownload ||
                    this.state.salesAgent == "" ||
                    this.state.date == ""
                  }
                  onClick={this.generateSalesAgentReport}
                >
                  {!this.state.loadingDownload &&
                  this.state.pdfGeneratedSuccessfully == null ? (
                    <MdOutlinePictureAsPdf className="loader-send-inside-div-button" />
                  ) : null}
                  {this.state.loadingDownload ? (
                    <Spinner className="loader-send-inside-div-button"></Spinner>
                  ) : null}
                  {this.state.pdfGeneratedSuccessfully != null &&
                  this.state.pdfGeneratedSuccessfully ? (
                    <FaCheck className="loader-send-inside-div-button" />
                  ) : null}

                  {this.props.localizedText["generate-and-save"]}
                </Button>
              </div>
              <div class="right-div">
                <div class="value-input">
                  {this.state.loadingDownload ? (
                    <div class="download-result fade-in-up">
                      <Spinner className="icon" />
                      <div class="info">
                        {
                          this.props.localizedText[
                            "download-is-being-processed"
                          ]
                        }
                      </div>
                    </div>
                  ) : null}
                  {!this.state.loadingDownload &&
                  this.state.pdfGeneratedSuccessfully != null &&
                  this.state.pdfGeneratedSuccessfully ? (
                    <div class="download-result fade-in-up">
                      <FaCheck className="icon" />
                      <div class="info">
                        {" "}
                        {
                          this.props.localizedText[
                            "your-download-was-sucessful"
                          ]
                        }
                      </div>
                      <div
                        class="download-link"
                        onClick={() => {
                          this.downloadPDF(
                            this.state.pdfGenerated.data,
                            this.state.pdfGenerated.name
                          );
                        }}
                      >
                        {this.state.pdfGenerated.name}
                      </div>
                      <div class="bottom-info">
                        {
                          this.props.localizedText[
                            "if-your-download-didnt-start-you-can-manually"
                          ]
                        }
                      </div>
                    </div>
                  ) : null}{" "}
                  {!this.state.loadingDownload &&
                  this.state.pdfGeneratedSuccessfully != null &&
                  !this.state.pdfGeneratedSuccessfully ? (
                    <div class="download-result">
                      <MdOutlineError className="icon" />
                      <div class="info">
                        {
                          this.props.localizedText[
                            "there-was-a-problem-processing-your-file"
                          ]
                        }
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>{" "}
            {this.state.loadingGenerate ? (
              <div class="loader-div download-result fade-in-up">
                <Spinner className="icon" />
                <div class="info">
                  {this.props.localizedText["preview-is-being-processed"]}
                </div>
              </div>
            ) : null}
            {this.state.htmlData != "" && !this.state.loadingGenerate ? (
              <div class="pdf-preview-div fade-in-up">
                <iframe
                  width="100%"
                  height="100%"
                  src={"data:text/html;base64," + this.state.htmlData}
                ></iframe>
              </div>
            ) : null}
            <ErrorMessageComponent
              errorMessage={
                this.props.localizedText[this.state.errorMessageType]
              }
            ></ErrorMessageComponent>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(SalesAgent);
