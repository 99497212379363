import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./conversion.css";
import ConversionService from "./conversionService";
import { utils } from "../../utils/utils";
import GoBackButton from "../../components/go-back-button/GoBackButton.jsx";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import { ImSearch } from "react-icons/im";
import ConversionComponent from "../../components/conversion-component/ConversionComponent.jsx";
import Modal from "react-bootstrap/Modal";
import ReceiptComponent from "../../components/receipt/Receipt.jsx";
import Result from "../../components/result/Result.jsx";
import { AiFillCopy } from "react-icons/ai";
import { FaCheck } from "react-icons/fa6";
import { Spinner } from "react-bootstrap";
import TotalBalance from "../../components/total-balance/TotalBalance.jsx";
import { LuPackageSearch } from "react-icons/lu";

class Conversion extends Component {
  state = {
    isMobileWidth: false,

    currencyFrom: "USDT",
    currencyTo: "USD",
    conversion: null,

    listCurrencies: [],
    listLockedRates: [],
    listLockedRatesShowed: [],
    listLockedRatesSkeleton: [1, 2, 3, 4, 5, 6],
    listNetworks: [],

    currentDate: new Date().toJSON(),

    network: "",
    qrcodeBase64: "",
    inboundAddress: "",
    inboundAddressCopied: false,
    transactionHash: "",

    invalidTransactionHash: false,

    showModalDepositQuestion: false,
    showModalDeposit: false,
    showModalSend: false,
    showModalConvert: false,
    showModalCancel: false,

    selectedDivIndex: null,
    selectedLockRate: {},

    loadingCurrencies: true,
    loadingConversion: false,
    loadingLockedRates: true,
    loadingSent: false,

    resultSuccess: true,
    resultType: "",

    stage: 1,
  };

  service = new ConversionService();

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.phoneVerified != null && !this.props.phoneVerified) {
      utils.goToURL(this.props, "/dashboard/settings/verify-phone");
    } else if (this.props.emailVerified != null && !this.props.emailVerified) {
      utils.goToURL(this.props, "/dashboard/settings/verify-email");
    } else if (this.props.idVerified != null && !this.props.idVerified) {
      utils.goToURL(this.props, "/dashboard/settings/id-verification");
    }

    utils.checkWindowWidth(
      this.setState.bind(this),
      this.props.changeGoBackNavBar.bind(this),
      true,
      "/dashboard"
    );
    window.addEventListener("resize", this.checkWindowWidth);
    this.interval = setInterval(
      () => this.setState({ currentDate: new Date().toJSON() }),
      1000
    );
    this.service.getCurrencies(this.props.token, this.setState.bind(this));
    this.service.getLockedRates(this.props.token, this.setState.bind(this));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props != prevProps) {
    }
  }

  componentWillUnmount() {}

  checkGoBackNavBar = () => {
    this.props.changeGoBackNavBar(false, null);
  };

  transformSeconds = (timeCreated, endTime) => {
    const currentDate = new Date();
    const createdDate = new Date(timeCreated);
    const endDate = new Date(endTime);

    // Ensure that the current date is within the range of timeCreated and endTime
    if (currentDate < createdDate || currentDate > endDate) {
      return this.props.localizedText["expired"];
    }

    // Calculate the time difference in seconds
    const timeDifference = Math.floor((endDate - currentDate) / 1000);
    if (timeDifference === null) {
      return this.props.localizedText["invalid-time-range"];
    }

    if (timeDifference < 60) {
      return (
        timeDifference +
        " " +
        this.props.localizedText["second"] +
        (timeDifference !== 1 ? "s" : "")
      );
    } else {
      const minutes = Math.floor(timeDifference / 60);
      const remainingSeconds = timeDifference % 60;
      return (
        minutes +
        " " +
        this.props.localizedText["minute"] +
        (minutes !== 1 ? "s " : " ") +
        this.props.localizedText["and"] +
        " " +
        remainingSeconds +
        " " +
        this.props.localizedText["second"] +
        (remainingSeconds !== 1 ? "s " : "")
      );
    }
  };

  getStatus = (card) => {
    console.log("card", card);
    if (card.status == "CANCELLED") {
      return this.props.localizedText["cancelled"];
    } else if (card.status == "EXECUTED") {
      return this.props.localizedText["executed"];
    } else {
      return this.props.localizedText["expired"];
    }
  };

  skeletonCards = ({ cards }) => (
    <div class="holding-div-list">
      {cards.map(() => (
        <div class="card-holding content-container-no-hover">
          <div class="detail-column">
            <div class="skeleton-box from-to-skeleton"></div>
            <div class="skeleton-box region-skeleton"></div>
          </div>
          <div class="type-column">
            <div class="skeleton-box type-skeleton"></div>
          </div>
          <div class="buttons-column">
            <div class="skeleton-box button-skeleton"></div>
          </div>
        </div>
      ))}
    </div>
  );

  handleChangeForNetwork = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    if (value != "" && value != null) {
      this.setState(
        {
          [name]: value,
          qrcodeBase64: "",
          loadingWallet: false,
        },
        () => {
          this.setState(
            {
              qrcodeBase64: "",
              loadingWallet: true,
            },
            () => {
              this.service.getWallet(
                this.props.token,
                this.state.selectedLockRate.from_currency,
                this.state.network,
                this.setState.bind(this)
              );
            }
          );
        }
      );
    } else {
      if (value == "") {
        this.setState({
          [name]: value,
          qrcodeBase64: "",
          loadingWallet: false,
        });
      }
    }
  };

  openDepositQuestion = (lockRate, index) => {
    this.setState({
      selectedDivIndex: index,
      selectedLockRate: lockRate,
      showModalDepositQuestion: true,
    });
  };

  handleCloseDepositQuestion = () => {
    this.setState({
      selectedDivIndex: null,
      showModalDepositQuestion: false,
      selectedLockRate: {},
    });
  };

  openDeposit = (currency) => {
    this.service.getNetworks(
      this.props.token,
      currency,
      this.setState.bind(this)
    );
    this.setState(
      {
        showModalDepositQuestion: false,
      },
      () => {
        this.setState({
          showModalDeposit: true,
        });
      }
    );
  };

  handleCloseDeposit = () => {
    this.setState({
      showModalDeposit: false,
      selectedDivIndex: null,
      selectedLockRate: {},
      network: "",
      qrcodeBase64: "",
    });
  };

  openSend = () => {
    this.setState(
      {
        showModalDeposit: false,
        showModalDepositQuestion: false,
      },
      () => {
        this.setState({
          showModalSend: true,
        });
      }
    );
  };

  handleCloseSend = () => {
    this.setState({
      selectedDivIndex: null,
      showModalSend: false,
      selectedLockRate: {},
    });
  };

  openConvert = (lockRate, index) => {
    this.setState({
      selectedDivIndex: index,
      selectedLockRate: lockRate,
      showModalConvert: true,
    });
  };

  handleCloseConvert = () => {
    this.setState({
      selectedDivIndex: null,
      showModalConvert: false,
    });
  };

  openCancel = (lockRate, index) => {
    this.setState({
      selectedDivIndex: index,
      selectedLockRate: lockRate,
      showModalCancel: true,
    });
  };

  handleCloseCancel = () => {
    this.setState({
      selectedDivIndex: null,
      showModalCancel: false,
    });
  };

  lockRateCallBack = () => {
    this.setState(
      {
        loadingLockedRates: true,
      },
      () => {
        this.service.getLockedRates(this.props.token, this.setState.bind(this));
      }
    );
  };

  convertNowCallback = (success) => {
    this.setState({
      selectedDivIndex: null,
      resultSuccess: success,
      resultType: "convert-now",
      stage: 2,
    });
  };

  getReceiptProps = (lockedRate) => {
    console.log("lockedRate", lockedRate);
    let data = [
      {
        title: "",
        data: [
          {
            name: this.props.localizedText["locked-rate-id"],
            value: lockedRate.locked_rate_id,
            type: "normal",
          },
          {
            name: this.props.localizedText["from---to"],
            value: lockedRate.from_currency + " - " + lockedRate.to_currency,
            type: "normal",
          },
          {
            name: this.props.localizedText["time"],
            value: this.transformSeconds(
              this.state.currentDate,
              lockedRate.expires_on
            ),
            type: "normal",
          },
          {
            currency: lockedRate.from_currency,
            name: this.props.localizedText["amount"],
            value: lockedRate.amount,
            type: "amount",
          },
          {
            currency: lockedRate.to_currency,
            name: this.props.localizedText["rate"],
            value: lockedRate.locked_rate,
            type: "amount",
          },
        ],
      },
    ];

    return data;
  };

  sendWithLockedRate = () => {
    if (this.state.transactionHash != "") {
      this.setState(
        {
          invalidTransactionHash: false,
          loadingSent: true,
        },
        () => {
          this.service.sendWithLockedRate(
            this.props.token,
            this.state.selectedLockRate.locked_rate_id,
            this.state.transactionHash,
            this.setState.bind(this)
          );
        }
      );
    } else {
      this.setState({ invalidTransactionHash: true });
    }
  };

  convertWithLockedRate = () => {
    this.setState(
      {
        loadingSent: true,
      },
      () => {
        this.service.convertWithLockedRate(
          this.props.token,
          this.state.selectedLockRate.locked_rate_id,
          this.setState.bind(this)
        );
      }
    );
  };

  cancelLockedRate = () => {
    this.setState(
      {
        loadingSent: true,
      },
      () => {
        this.service.cancelLockedRate(
          this.props.token,
          this.state.selectedLockRate.locked_rate_id,
          this.setState.bind(this)
        );
      }
    );
  };

  copyText = () => {
    this.setState({
      inboundAddressCopied: true,
    });
    navigator.clipboard.writeText(this.state.inboundAddress);
  };

  getResultMessage = () => {
    if (this.state.resultType == "send-with-locked-rate") {
      if (this.state.resultSuccess) {
        return this.props.localizedText["the-transaction-was-successful"];
      } else {
        return this.props.localizedText[
          "unfortunately,-we-have-encountered-a-problem-while-trying-to-send-the-currency"
        ];
      }
    } else if (this.state.resultType == "convert-with-locked-rate") {
      if (this.state.resultSuccess) {
        return this.props.localizedText["the-conversion-was-successful"];
      } else {
        return this.props.localizedText[
          "unfortunately,-we-have-encountered-a-problem-while-trying-to-convert-the-currency"
        ];
      }
    } else if (this.state.resultType == "cancel-locked-rate") {
      if (this.state.resultSuccess) {
        return this.props.localizedText[
          "the-locked-rated-was-canceled-successfully"
        ];
      } else {
        return this.props.localizedText[
          "unfortunately,-we-have-encountered-a-problem-while-trying-to-cancel-the-locked-rate"
        ];
      }
    } else if (this.state.resultType == "convert-now") {
      if (this.state.resultSuccess) {
        return this.props.localizedText["the-conversion-was-successful"];
      } else {
        return this.props.localizedText[
          "unfortunately,-we-have-encountered-a-problem-while-trying-to-convert-the-currency"
        ];
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <div class="conversion-container">
          <div class="conversion-body">
            <div class="side-container">
              <GoBackButton
                region={this.props.region}
                localizedText={this.props.localizedText}
                goBack={"/dashboard"}
              ></GoBackButton>
            </div>
            <div class="middle-container container-padding">
              {this.state.stage == 1 ? (
                <div class="conversion-div">
                  <TotalBalance
                    region={this.props.region}
                    localizedText={this.props.localizedText}
                    account={this.props.account}
                    token={this.props.token}
                  ></TotalBalance>
                  <div class="bottom-section">
                    <div class="conversion-component">
                      {" "}
                      <div class="from-to-div">
                        <div class="input-group-div fade-in-up">
                          <div class="label">
                            {this.props.localizedText["from"]}
                          </div>
                          <InputGroup className="input-field dropdown-filter">
                            <Form.Select
                              variant="outline-secondary"
                              onChange={(e) =>
                                utils.handleChange(e, this.setState.bind(this))
                              }
                              name="currencyFrom"
                              style={{
                                width: "100%",
                                flex: "0 1 auto",
                                textAlign: "start",
                              }}
                              disabled={this.state.loadingSent}
                              value={this.state.currencyFrom}
                            >
                              <option></option>
                              {this.state.listCurrencies.map((currency) => (
                                <option value={currency.base_currency}>
                                  {currency.name +
                                    " (" +
                                    currency.base_currency +
                                    ")"}
                                </option>
                              ))}
                            </Form.Select>
                          </InputGroup>
                        </div>
                        <div class="input-group-div fade-in-up">
                          <div class="label">
                            {this.props.localizedText["to"]}
                          </div>
                          <InputGroup className="input-field dropdown-filter">
                            <Form.Select
                              variant="outline-secondary"
                              onChange={(e) =>
                                utils.handleChange(e, this.setState.bind(this))
                              }
                              name="currencyTo"
                              style={{
                                width: "100%",
                                flex: "0 1 auto",
                                textAlign: "start",
                              }}
                              value={this.state.currencyTo}
                            >
                              <option></option>
                              {this.state.listCurrencies.map((currency) => (
                                <option value={currency.base_currency}>
                                  {currency.name +
                                    " (" +
                                    currency.base_currency +
                                    ")"}
                                </option>
                              ))}
                            </Form.Select>
                          </InputGroup>
                        </div>
                      </div>
                      <ConversionComponent
                        amountFrom={0}
                        currencyFrom={this.state.currencyFrom}
                        currencyTo={this.state.currencyTo}
                        component={false}
                        lockConversionCallBackFunction={this.lockRateCallBack}
                        convertNowCallBackFunction={this.convertNowCallback}
                        region={this.props.region}
                        localizedText={this.props.localizedText}
                        token={this.props.token}
                      ></ConversionComponent>{" "}
                    </div>
                    <div class="holding-div fade-in-up">
                      <div class="filter-search-div">
                        <InputGroup className="search-bar">
                          <ImSearch class="search-icon"></ImSearch>
                          <FormControl
                            onChange={(e) => {
                              utils.editSearchTermDefault(
                                e,
                                this.setState.bind(this),
                                this.state.listLockedRates,
                                "listLockedRatesShowed",
                                ["from_currency", "to_currency"]
                              );
                            }}
                            type="search"
                            id="search-bar-entities"
                            placeholder={
                              this.props.localizedText["search-locked-rates"]
                            }
                            autoComplete="off"
                          />
                        </InputGroup>
                      </div>
                      {this.state.loadingLockedRates ? (
                        <this.skeletonCards
                          cards={this.state.listLockedRatesSkeleton}
                        ></this.skeletonCards>
                      ) : null}

                      {!this.state.loadingLockedRates &&
                      Object.keys(this.state.listLockedRatesShowed).length >=
                        0 ? (
                        <div class="holding-div-list">
                          {" "}
                          {this.state.listLockedRatesShowed.map(
                            (hold, index) => (
                              <div
                                class={
                                  (hold.status == "CREATED" ||
                                    hold.status == "PARTIAL_EXECUTION") &&
                                  this.transformSeconds(
                                    this.state.currentDate,
                                    hold.expires_on
                                  ) != "Expired"
                                    ? "card-holding content-container-no-hover"
                                    : "card-holding disabled-element content-container-no-hover"
                                }
                              >
                                <div class="detail-column">
                                  <div class="from-to">
                                    {hold.from_currency +
                                      " - " +
                                      hold.to_currency}
                                  </div>
                                  <div class="rate">
                                    {utils.roundDecimals(
                                      hold.to_currency,
                                      hold.locked_rate,
                                      4
                                    )}
                                  </div>
                                  <div class="time-left">
                                    {hold.status == "CREATED" ||
                                    hold.status == "PARTIAL_EXECUTION"
                                      ? this.transformSeconds(
                                          this.state.currentDate,
                                          hold.expires_on
                                        )
                                      : this.getStatus(hold)}
                                  </div>
                                </div>
                                <div class="type-column">
                                  <div class="type">
                                    {hold.locked_rate + " " + hold.to_currency}
                                  </div>
                                </div>
                                <div class="buttons-column">
                                  <Button
                                    className="inside-primary-button lock-rate-operation-button"
                                    onClick={this.openDepositQuestion.bind(
                                      this,
                                      hold,
                                      index
                                    )}
                                    disabled={
                                      (hold.status != "CREATED" &&
                                        hold.status != "PARTIAL_EXECUTION") ||
                                      this.transformSeconds(
                                        this.state.currentDate,
                                        hold.expires_on
                                      ) == "Expired"
                                    }
                                  >
                                    {
                                      this.props.localizedText[
                                        "send-token-with-rate"
                                      ]
                                    }
                                  </Button>
                                  <Button
                                    className="inside-primary-button lock-rate-operation-button"
                                    onClick={this.openConvert.bind(
                                      this,
                                      hold,
                                      index
                                    )}
                                    disabled={
                                      (hold.status != "CREATED" &&
                                        hold.status != "PARTIAL_EXECUTION") ||
                                      this.transformSeconds(
                                        this.state.currentDate,
                                        hold.expires_on
                                      ) == "Expired"
                                    }
                                  >
                                    {
                                      this.props.localizedText[
                                        "convert-with-rate"
                                      ]
                                    }
                                  </Button>
                                  <Button
                                    className="inside-primary-button"
                                    onClick={this.openCancel.bind(
                                      this,
                                      hold,
                                      index
                                    )}
                                    disabled={
                                      (hold.status != "CREATED" &&
                                        hold.status != "PARTIAL_EXECUTION") ||
                                      this.transformSeconds(
                                        this.state.currentDate,
                                        hold.expires_on
                                      ) == "Expired"
                                    }
                                  >
                                    {this.props.localizedText["cancel-rate"]}
                                  </Button>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      ) : null}

                      {!this.state.loadingLockedRates &&
                      Object.keys(this.state.listLockedRatesShowed).length ==
                        0 ? (
                        <div class="no-list-div">
                          <LuPackageSearch className="empty-icon" />
                          <div class="no-list-title">
                            {this.props.localizedText["no-lock-rates-yet"]}
                          </div>
                          <div class="no-list-sub-title">
                            {
                              this.props.localizedText[
                                "select-a-currency-and-enter-an-amount-to-lock-a-rate"
                              ]
                            }
                          </div>
                        </div>
                      ) : null}
                    </div>{" "}
                  </div>
                </div>
              ) : (
                <Result
                  success={this.state.resultSuccess}
                  secondButton={false}
                  title={
                    this.props.localizedText[
                      utils.getResultTitle(this.state.resultSuccess)
                    ]
                  }
                  message={this.getResultMessage()}
                  primaryButtonText={this.props.localizedText["continue"]}
                  primaryButtonFunction={utils.goToURL.bind(
                    this,
                    this.props,
                    "/dashboard/"
                  )}
                ></Result>
              )}
            </div>
            <div class="side-container"></div>
          </div>
        </div>

        <Modal
          show={this.state.showModalDepositQuestion}
          onHide={this.handleCloseDepositQuestion}
          className="primary-modal"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.localizedText["do-you-need-to-deposit?"]}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              this.props.localizedText[
                "would-you-like-to-deposit-tokens-into-your-account?"
              ]
            }
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="outside-secondary-button"
              onClick={this.openDeposit.bind(
                this,
                this.state.selectedLockRate.from_currency
              )}
            >
              {this.props.localizedText["deposit"]}
            </Button>
            <Button className="outside-primary-button" onClick={this.openSend}>
              {this.props.localizedText["continue"]}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showModalDeposit}
          onHide={this.handleCloseDeposit}
          className="primary-modal"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.localizedText["deposit-token"]}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="modal-input-div">
              <div class="modal-input-label">
                {this.props.localizedText["token"]}
              </div>
              <InputGroup className="input-field">
                <Form.Select
                  variant="outline-secondary"
                  style={{
                    width: "100%",
                    flex: "0 1 auto",
                    textAlign: "start",
                  }}
                  className="input-field-modal"
                  disabled
                >
                  <option>{this.state.selectedLockRate.from_currency}</option>
                </Form.Select>
              </InputGroup>
            </div>
            <div class="modal-input-div">
              <div class="modal-input-label">
                {this.props.localizedText["network"]}
              </div>
              <InputGroup className="input-field">
                <Form.Select
                  variant="outline-secondary"
                  name="network"
                  onChange={(e) => this.handleChangeForNetwork(e)}
                  style={{
                    width: "100%",
                    flex: "0 1 auto",
                    textAlign: "start",
                  }}
                  className="input-field-modal"
                  disabled={
                    this.state.loadingSent ||
                    this.state.listNetworks.length <= 1
                  }
                >
                  {this.state.listNetworks.length == 1 ? (
                    <option>{this.state.listNetworks[0].description}</option>
                  ) : (
                    <option></option>
                  )}
                  {this.state.listNetworks.map((network) => (
                    <option value={network.blockchain}>
                      {network.description}
                    </option>
                  ))}
                </Form.Select>
              </InputGroup>
            </div>
            {this.state.loadingWallet ? (
              <div class="fade-in-up">
                <Spinner className="loading-conversions" />
              </div>
            ) : null}
            {!this.state.loadingWallet && this.state.qrcodeBase64 != "" ? (
              <div class="conversion-deposit-modal-body fade-in-up">
                <img
                  className="qrcode"
                  src={`data:image/png;base64, ${this.state.qrcodeBase64}`}
                />
                <div class="key-div" onClick={this.copyText}>
                  <div class="key">{this.state.inboundAddress}</div>
                  {this.state.inboundAddressCopied ? (
                    <FaCheck class="copy-success-icon" />
                  ) : (
                    <AiFillCopy class="copy-key-icon" />
                  )}
                </div>
              </div>
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="outside-secondary-button"
              onClick={this.handleCloseDeposit}
            >
              {this.props.localizedText["close"]}
            </Button>
            <Button className="outside-primary-button" onClick={this.openSend}>
              {this.props.localizedText["continue"]}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showModalSend}
          onHide={this.handleCloseSend}
          className="primary-modal"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.localizedText["send-token-with-locked-rate"]}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="modal-input-div">
              <div class="modal-input-label">
                {this.props.localizedText["transaction-hash"]}
              </div>
              <Form.Group style={{ "flex-grow": "1" }}>
                <FormControl
                  name="transactionHash"
                  value={this.state.transactionHash}
                  onChange={(e) =>
                    utils.handleChange(e, this.setState.bind(this))
                  }
                  isInvalid={this.state.invalidTransactionHash}
                  className="input-field input-field-modal"
                  disabled={this.state.loadingSent}
                />
              </Form.Group>
            </div>
            {Object.keys(this.state.selectedLockRate).length > 0 ? (
              <ReceiptComponent
                data={this.getReceiptProps(this.state.selectedLockRate)}
              ></ReceiptComponent>
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="back-button outside-secondary-button"
              onClick={this.handleCloseSend}
              disabled={this.state.loadingSent}
            >
              {this.props.localizedText["close"]}
            </Button>
            <Button
              className="back-button outside-primary-button"
              disabled={this.state.loadingSent}
              onClick={this.sendWithLockedRate}
            >
              {this.state.loadingSent ? (
                <Spinner className="loader-send-outside-div-button"></Spinner>
              ) : null}
              {this.props.localizedText["send"]}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showModalConvert}
          onHide={this.handleCloseConvert}
          className="primary-modal"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.localizedText["convert-asset-with-locked-rate"]}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {Object.keys(this.state.selectedLockRate).length > 0 ? (
              <ReceiptComponent
                data={this.getReceiptProps(this.state.selectedLockRate)}
              ></ReceiptComponent>
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="back-button outside-secondary-button"
              onClick={this.handleCloseConvert}
              disabled={this.state.loadingSent}
            >
              {this.props.localizedText["close"]}
            </Button>
            <Button
              className="back-button outside-primary-button"
              disabled={this.state.loadingSent}
              onClick={this.convertWithLockedRate}
            >
              {" "}
              {this.state.loadingSent ? (
                <Spinner className="loader-send-outside-div-button"></Spinner>
              ) : null}
              {this.props.localizedText["convert"]}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showModalCancel}
          onHide={this.handleCloseCancel}
          className="primary-modal"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {" "}
              {this.props.localizedText["cancel-locked-rate"]}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {Object.keys(this.state.selectedLockRate).length > 0 ? (
              <ReceiptComponent
                data={this.getReceiptProps(this.state.selectedLockRate)}
              ></ReceiptComponent>
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="back-button outside-secondary-button"
              onClick={this.handleCloseCancel}
              disabled={this.state.loadingSent}
            >
              {this.props.localizedText["close"]}
            </Button>
            <Button
              className="back-button outside-primary-button"
              disabled={this.state.loadingSent}
              onClick={this.cancelLockedRate}
            >
              {" "}
              {this.state.loadingSent ? (
                <Spinner className="loader-send-outside-div-button"></Spinner>
              ) : null}
              {this.props.localizedText["cancel"]}
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withRouter(Conversion);
