import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./monthlyPaymentReport.css";
import MonthlyPaymentReportService from "./MonthlyPaymentReportService";
import Form from "react-bootstrap/Form";
import { utils } from "../../utils/utils.jsx";
import { Spinner } from "react-bootstrap";
import ErrorMessageComponent from "../../components/errorMessage/ErrorMessage.jsx";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCheck, FaCalendar } from "react-icons/fa6";
import TotalBalance from "../../components/total-balance/TotalBalance.jsx";
import GoBackButton from "../../components/go-back-button/GoBackButton.jsx";
import Button from "react-bootstrap/Button";
import { MdOutlinePictureAsPdf, MdOutlineError } from "react-icons/md";

class MonthlyPaymentReport extends Component {
  state = {
    width: window.innerWidth,

    startDate: "",
    endDate: "",
    htmlData: "",

    loadingGenerate: false,
    loadingDownload: false,
    pdfGeneratedSuccessfully: null,
  };

  service = new MonthlyPaymentReportService();

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    utils.checkWindowWidth(
      this.setState.bind(this),
      this.props.changeGoBackNavBar.bind(this),
      true,
      this.props.tryToGoBackStage
    );
    window.addEventListener(
      "resize",
      utils.checkWindowWidth.bind(
        this,
        this.setState.bind(this),
        this.props.changeGoBackNavBar.bind(this),
        true,
        this.props.tryToGoBackStage
      )
    );
  }

  componentDidUpdate(prevProps, prevState) {}

  setMessages = (type) => {
    this.setState({
      errorMessageType: type,
    });
  };

  setDate = (type, date) => {
    this.setState(
      {
        [type]: utils.formatDateReverse(date),
      },
      () => {
        if (this.state.startDate != "" && this.state.endDate != "") {
          this.setState(
            {
              loadingGenerate: true,
              loadingDownload: false,
              pdfGeneratedSuccessfully: null,
            },
            () => {
              this.service.generateMonthlyReport(
                this.props.token,
                this.state.startDate,
                this.state.endDate,
                "HTML",
                this.setState.bind(this),
                this.setMessages.bind(this)
              );
            }
          );
        }
      }
    );
  };

  generateDailyFile = () => {
    this.setState(
      {
        loadingDownload: true,
        pdfGeneratedSuccessfully: null,
      },
      () => {
        this.service.generateMonthlyReport(
          this.props.token,
          this.state.startDate,
          this.state.endDate,
          "PDF",
          this.setState.bind(this),
          this.setMessages.bind(this),
          this.downloadPDF.bind(this)
        );
      }
    );
  };

  downloadPDF = (data, name) => {
    console.log("name", name);
    utils.generatePDF(name, data, this.setState.bind(this));
  };

  checkChooseStartDate = () => {
    this.setState(
      {
        chooseStartDate: !this.state.chooseStartDate,
      },
      () => {
        if (!this.state.chooseStartDate) {
          this.setState({
            startDate: "",
          });
        }
      }
    );
  };

  getMonthLocalizedText = (value) => {
    const months = {
      January: this.props.localizedText["January"],
      February: this.props.localizedText["February"],
      March: this.props.localizedText["March"],
      April: this.props.localizedText["April"],
      May: this.props.localizedText["May"],
      June: this.props.localizedText["June"],
      July: this.props.localizedText["July"],
      August: this.props.localizedText["August"],
      September: this.props.localizedText["September"],
      October: this.props.localizedText["October"],
      November: this.props.localizedText["November"],
      December: this.props.localizedText["December"],
    };

    return months[value] || value;
  };

  renderMonthContent = (month, shortMonth, longMonth, day) => {
    const fullYear = new Date(day).getFullYear();
    const tooltipText = this.getMonthLocalizedText(longMonth);

    return (
      <span title={tooltipText}>
        {this.getMonthLocalizedText(longMonth).substr(0, 3)}
      </span>
    );
  };

  render() {
    return (
      <React.Fragment>
        <div class="monthly-payment-report-container">
          <div class="monthly-payment-report-body">
            <div class="side-container">
              <GoBackButton
                region={this.props.region}
                localizedText={this.props.localizedText}
                goBack={this.tryToGoBackStage}
              ></GoBackButton>
            </div>
            <div class="middle-container container-padding">
              <TotalBalance
                account={this.props.account}
                token={this.props.token}
                region={this.props.region}
                localizedText={this.props.localizedText}
              ></TotalBalance>
              <div class="title fade-in-up">
                {" "}
                {this.props.localizedText["monthly-payment-report"]}
              </div>

              <div class="section">
                <div class="left-input content-container">
                  <div class="div-parent-flex-row mb-3">
                    <Form.Group className="div-50-son">
                      <Form.Label className="input-label-inside-div">
                        {this.props.localizedText["start-date"]}
                      </Form.Label>
                      <DatePicker
                        className={
                          this.state.loadingDownload
                            ? "date-picker-report disabled"
                            : "date-picker-report"
                        }
                        showIcon
                        onChange={(date) => this.setDate("startDate", date)}
                        selected={Date.parse(this.state.startDate)}
                        icon={<FaCalendar className="calendar-icon" />}
                        disabled={this.state.loadingDownload}
                      />
                    </Form.Group>

                    <Form.Group className="div-50-son">
                      <Form.Label className="input-label-inside-div">
                        {this.props.localizedText["end-date"]}
                      </Form.Label>
                      <DatePicker
                        className={
                          this.state.loadingDownload
                            ? "date-picker-report disabled"
                            : "date-picker-report"
                        }
                        showIcon
                        onChange={(date) => this.setDate("endDate", date)}
                        selected={Date.parse(this.state.endDate)}
                        icon={<FaCalendar className="calendar-icon" />}
                        disabled={this.state.loadingDownload}
                      />
                    </Form.Group>
                  </div>{" "}
                  <Button
                    className="inside-primary-button generate-pdf-buton"
                    disabled={
                      this.state.loadingDownload ||
                      this.state.startDate == "" ||
                      this.state.endDate == ""
                    }
                    onClick={this.generateDailyFile}
                  >
                    {!this.state.loadingDownload &&
                    this.state.pdfGeneratedSuccessfully == null ? (
                      <MdOutlinePictureAsPdf className="loader-send-inside-div-button" />
                    ) : null}
                    {this.state.loadingDownload ? (
                      <Spinner className="loader-send-inside-div-button"></Spinner>
                    ) : null}
                    {this.state.pdfGeneratedSuccessfully != null &&
                    this.state.pdfGeneratedSuccessfully ? (
                      <FaCheck className="loader-send-inside-div-button" />
                    ) : null}

                    {this.props.localizedText["generate-and-save"]}
                  </Button>
                </div>
                <div class="right-div">
                  <div class="value-input">
                    {!this.state.loadingGenerate &&
                    this.state.loadingDownload ? (
                      <div class="download-result fade-in-up">
                        <Spinner className="icon" />
                        <div class="info">
                          {
                            this.props.localizedText[
                              "download-is-being-processed"
                            ]
                          }
                        </div>
                      </div>
                    ) : null}
                    {!this.state.loadingGenerate &&
                    !this.state.loadingDownload &&
                    this.state.pdfGeneratedSuccessfully != null &&
                    this.state.pdfGeneratedSuccessfully ? (
                      <div class="download-result fade-in-up">
                        <FaCheck className="icon" />
                        <div class="info">
                          {" "}
                          {
                            this.props.localizedText[
                              "your-download-was-sucessful"
                            ]
                          }
                        </div>
                        <div
                          class="download-link"
                          onClick={() => {
                            this.downloadPDF(
                              this.state.pdfGenerated.data,
                              "ds" + "-" + this.state.pdfGenerated.endDate
                            );
                          }}
                        >
                          {this.state.pdfGenerated.name}
                        </div>
                        <div class="bottom-info">
                          {
                            this.props.localizedText[
                              "if-your-download-didnt-start-you-can-manually"
                            ]
                          }
                        </div>
                      </div>
                    ) : null}{" "}
                    {!this.state.loadingGenerate &&
                    !this.state.loadingDownload &&
                    this.state.pdfGeneratedSuccessfully != null &&
                    !this.state.pdfGeneratedSuccessfully ? (
                      <div class="download-result">
                        <MdOutlineError className="icon" />
                        <div class="info">
                          {
                            this.props.localizedText[
                              "there-was-a-problem-processing-your-file"
                            ]
                          }
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              {this.state.loadingGenerate ? (
                <div class="loader-div download-result fade-in-up">
                  <Spinner className="icon" />
                  <div class="info">
                    {this.props.localizedText["preview-is-being-processed"]}
                  </div>
                </div>
              ) : null}
              {this.state.htmlData != "" && !this.state.loadingGenerate ? (
                <div class="pdf-preview-div fade-in-up">
                  <iframe
                    width="100%"
                    height="100%"
                    src={"data:text/html;base64," + this.state.htmlData}
                  ></iframe>
                </div>
              ) : null}
              <ErrorMessageComponent
                errorMessage={
                  this.props.localizedText[this.state.errorMessageType]
                }
              ></ErrorMessageComponent>
            </div>
            <div class="side-container"></div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(MonthlyPaymentReport);
