import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./transactionConfirmation.css";
import TransactionConfirmationService from "./TransactionConfirmationService.jsx";
import TotalBalance from "../total-balance/TotalBalance.jsx";
import { CiCircleAlert } from "react-icons/ci";
import Button from "react-bootstrap/Button";
import { Spinner } from "react-bootstrap";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { utils } from "../../utils/utils";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import ErrorMessageComponent from "../../components/errorMessage/ErrorMessage.jsx";

class TransactionConfirmation extends Component {
  state = {
    account: {},

    code: "",
    authChoice: "",
    confirmPhoneMessage: "",

    isHidden: false,
  };

  service = new TransactionConfirmationService();

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.addEventListener("resize", this.checkWindowWidth);

    if (this.props.account) {
      this.setState({
        account: this.props.account,
      });
    } else {
      //ComponentDidUpdateWill take care of it
    }

    this.service.getClientConfig(this.props.token, this.setState.bind(this));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props != prevProps) {
      if (this.props.account) {
        this.setState({
          account: this.props.account,
        });
      }
    }
  }

  toggleHidden = (value) => {
    this.setState({
      isHidden: value,
    });
  };

  confirmPhone = () => {
    this.setState(
      {
        loadingPhone: true,
        inputKey: this.state.inputKey + 1,
        code: "",
        invalidCode: false,
      },
      () => {
        this.service.confirmPhone(this.props.token, this.setState.bind(this));
      }
    );
  };

  getDynamicLabel = (key) => {
    if (key == "amount") {
      return this.props.localizedText["amount"];
    } else if (key == "source_fee") {
      return this.props.localizedText["source-fee"];
    } else if (key == "pay_fee") {
      return this.props.localizedText["pay-fee"];
    } else {
      return key;
    }
  };

  render() {
    return (
      <React.Fragment>
        <div class="transaction-confirmation-container">
          <div class="transaction-confirmation-body">
            <div class="confirmation-main-title">
              {this.props.localizedText["confirmation"]}
            </div>
            <div class="amount-div">
              {this.props.totalAmounts.map((totalAmount) => (
                <div class="total-info-div">
                  {" "}
                  <div class="amount">
                    {this.props.localizedText["total-amount"] +
                      " (" +
                      totalAmount.currency +
                      ") "}
                  </div>
                  <div class="equal">
                    ={" "}
                    {utils.roundDecimals("USD", totalAmount.amount) +
                      " (" +
                      totalAmount.currency +
                      ") "}
                  </div>
                </div>
              ))}

              {this.props.fees != null &&
              Object.keys(this.props.fees).length > 0 ? (
                <div>
                  {Object.keys(this.props.fees).map((key) => (
                    <div>
                      {key == "amount" ? (
                        <div class="fee-details-row">
                          <div>{this.getDynamicLabel(key)}</div>
                          <div class="value">
                            {utils.roundDecimals(
                              this.props.currencyTo,
                              Number(this.props.fees[key])
                            ) +
                              " (" +
                              this.props.currencyFrom +
                              ") "}
                          </div>
                        </div>
                      ) : null}
                      {key != "total" && key != "amount" ? (
                        <div class="fee-details-row">
                          <div>{this.getDynamicLabel(key)}</div>
                          <div class="value">
                            {utils.roundDecimals(
                              this.props.currencyTo,
                              Number(this.props.fees[key].amount)
                            ) +
                              " (" +
                              this.props.fees[key].currency +
                              ") "}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
            <div class="card-title hidden-icons-div">
              <div class="card-title">{this.props.localizedText["from"]}</div>

              {this.state.isHidden ? (
                <BsEyeSlashFill
                  class="hide isHidden"
                  onClick={this.toggleHidden.bind(this, false)}
                />
              ) : (
                <BsEyeFill
                  class="hide"
                  onClick={this.toggleHidden.bind(this, true)}
                />
              )}
            </div>
            <TotalBalance
              account={this.props.account}
              region={this.props.region}
              localizedText={this.props.localizedText}
              noHideButton={true}
              isHidden={this.state.isHidden}
              token={this.props.token}
            ></TotalBalance>

            <div class="card-title">{this.props.localizedText["to"]}</div>
            <div class="account-info">
              <div class="left">
                <div class="image"></div>
                <div class="text">
                  <div class="title">{this.props.name}</div>
                  <div class="id">{this.props.id}</div>
                  <div class="type">{this.props.type}</div>
                </div>
              </div>
            </div>
            <div class="alert-div">
              <CiCircleAlert class="icon"></CiCircleAlert>
              <div class="alert-text">
                {
                  this.props.localizedText[
                    "use-secure,-verified-payment-methods-for-safer-online-transactions."
                  ]
                }
              </div>
            </div>
            <div class="form-div">
              <Form.Group className="mb-3">
                <Form.Label className="input-label-outside-div">
                  {this.props.localizedText["code"]}
                </Form.Label>
                <FormControl
                  name="code"
                  value={this.state.code}
                  onChange={(e) =>
                    utils.handleChange(e, this.setState.bind(this))
                  }
                  isInvalid={this.props.invalidCode}
                  className="input-field"
                  disabled={this.props.loadingSent}
                  autoComplete="off"
                />
              </Form.Group>

              {this.state.authChoice == "Phone" ? (
                <div class="auth-div">
                  {
                    this.props.localizedText[
                      "a-text-message-with-a-8-digit-verification-code-was-just-sent-to"
                    ]
                  }
                  &nbsp;
                  {this.state.message + " "}
                  <b onClick={this.confirmPhone}>
                    {" "}
                    {this.props.localizedText["resend-code"]}
                  </b>
                </div>
              ) : (
                <div class="auth-div">
                  {
                    this.props.localizedText[
                      "please-enter-the-code-from-your-authenticator-app"
                    ]
                  }
                  <br />
                  <b
                    onClick={utils.goToURL.bind(
                      this,
                      this.props,
                      "/dashboard/settings"
                    )}
                  >
                    {this.props.localizedText["change-auth-method"]}
                  </b>
                </div>
              )}
            </div>
            <ErrorMessageComponent
              errorMessage={this.props.errorMessage}
            ></ErrorMessageComponent>
            <div class="buttons-div">
              <Button
                className="primary-button outside-primary-button"
                onClick={this.props.primaryButtonFunction.bind(
                  this,
                  this.state.code,
                  this.state.authChoice
                )}
                disabled={this.props.loadingSent}
              >
                {this.props.loadingSent ? (
                  <Spinner className="loader-send-outside-div-button"></Spinner>
                ) : null}
                {this.props.primaryButtonText}
              </Button>
              <Button
                className="secondary-button outside-secondary-button"
                onClick={this.props.secondaryButtonFunction}
              >
                {this.props.secondaryButtonText}
              </Button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(TransactionConfirmation);
