import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./receipt.css";
import { utils } from "../../utils/utils";

class ReceiptComponent extends Component {
  state = {};

  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {}

  render() {
    return (
      <React.Fragment>
        {this.props.data.map((item) => (
          <div class="receipt-container">
            <div class="receipt-title">{item.title}</div>
            {item.data.map((data) => (
              <div>
                {data.type == "normal" ? (
                  <div
                    class={
                      "receipt-row " +
                      (this.props.centered ? "receipt-row-centered" : "")
                    }
                  >
                    <div class="key">{data.name}</div>
                    <div class="value">{data.value}</div>
                  </div>
                ) : null}
                {data.type == "amount" ? (
                  <div
                    class={
                      "receipt-row " +
                      (this.props.centered ? "receipt-row-centered" : "")
                    }
                  >
                    <div class="key">{data.name}</div>
                    <div class="value">
                      {utils.roundDecimals(data.currency, data.value)}
                    </div>
                  </div>
                ) : null}
                {data.type == "date" ? (
                  <div
                    class={
                      "receipt-row " +
                      (this.props.centered ? "receipt-row-centered" : "")
                    }
                  >
                    <div class="key">{data.name}</div>
                    <div class="value">
                      {" "}
                      {utils.formatDateUSWithTime(data.value)}
                    </div>
                  </div>
                ) : null}
                {process.env.REACT_APP_ENV != "prod" &&
                data.type.toLowerCase() == "status" ? (
                  <div
                    class={
                      "receipt-row status-row " +
                      (this.props.centered ? "receipt-row-centered" : "")
                    }
                  >
                    <div class="key">{data.name}</div>
                    <div class="value">
                      <div class={"status-value " + data.class}>
                        {data.value.toLowerCase()}{" "}
                      </div>
                    </div>
                  </div>
                ) : null}
                {data.type == "fees" ? (
                  <div
                    class={
                      "receipt-row " +
                      (this.props.centered ? "receipt-row-centered" : "")
                    }
                  >
                    {data.fees.map((fee) => (
                      <div class="payment-method-info-row">
                        <div>{fee.name}</div>
                        <div>{fee.amount + fee.currency}</div>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        ))}
      </React.Fragment>
    );
  }
}

export default withRouter(ReceiptComponent);
