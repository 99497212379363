import { portfolioAPI } from "../../../apis/PortfolioAPI.jsx";

export default class CompanyDailyStatisticsService {
  getSalesAgents(token, setState) {
    portfolioAPI
      .getSalesAgents(token)
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("getSalesAgents success", response);
          setState({
            loadingSalesAgent: false,
            listSalesAgent: response.data,
          });
        } else {
          console.log("getSalesAgents failure", response);
          setState({
            loadingSalesAgent: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log("getSalesAgents error", error);
        setState({
          loadingSalesAgent: false,
        });
      });
  }

  generateSalesAgentReport(
    token,
    month,
    year,
    salesId,
    format,
    setState,
    setMessages,
    downloadPDF
  ) {
    portfolioAPI
      .generateSalesAgentReport(token, month, year, salesId, format)
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("generateSalesAgentReport success", response);
          if (format == "HTML") {
            setState({
              htmlData: response.data,
              loadingGenerate: false,
            });
          } else {
            setState({
              loadingGenerate: false,
              pdfGenerated: {
                name: month + "-" + year + ".pdf",
                data: response.data,
              },
            });
            downloadPDF(response.data, month + "-" + year + ".pdf");
          }
        } else {
          console.log("generateSalesAgentReport failure", response);
          setMessages(
            "*an-error-has-ocurred-while-trying-to-generate-the-file"
          );
          if (format == "HTML") {
            setState({
              loadingGenerate: false,
            });
          } else {
            setState({
              loadingDownload: false,
            });
          }
        }
      })
      .catch((error) => {
        console.log("generateSalesAgentReport error", error);
        setMessages("*an-error-has-ocurred-while-trying-to-generate-the-file");
        if (format == "HTML") {
          setState({
            loadingGenerate: false,
          });
        } else {
          setState({
            loadingDownload: false,
          });
        }
      });
  }
}
