import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./companyDailyStatistics.css";
import CompanyDailyStatisticsService from "./CompanyDailyStatisticsService.jsx";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { utils } from "../../../utils/utils.jsx";
import { Spinner } from "react-bootstrap";
import ErrorMessageComponent from "../../errorMessage/ErrorMessage.jsx";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCheck, FaCalendar } from "react-icons/fa6";
import { MdOutlinePictureAsPdf, MdOutlineError } from "react-icons/md";

class CompanyDailyStatistics extends Component {
  state = {
    width: window.innerWidth,

    startDate: "",
    endDate: "",

    loadingDownload: false,
    pdfGeneratedSuccessfully: null,
    pdfGenerated: {},

    chooseStartDate: false,
  };

  service = new CompanyDailyStatisticsService();

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    utils.checkWindowWidth(
      this.setState.bind(this),
      this.props.changeGoBackNavBar.bind(this),
      true,
      this.props.tryToGoBackStage
    );
    window.addEventListener(
      "resize",
      utils.checkWindowWidth.bind(
        this,
        this.setState.bind(this),
        this.props.changeGoBackNavBar.bind(this),
        true,
        this.props.tryToGoBackStage
      )
    );
  }

  componentDidUpdate(prevProps, prevState) {}

  setMessages = (type) => {
    this.setState({
      errorMessageType: type,
    });
  };

  setStartDate = (date) => {
    this.setState({
      startDate: utils.formatDateUS(date),
    });
  };

  setEndDate = (date) => {
    this.setState({
      endDate: utils.formatDateUS(date),
    });
  };

  generateDailyFile = () => {
    this.setState(
      {
        loadingDownload: true,
        pdfGeneratedSuccessfully: null,
        pdfGenerated: {},
      },
      () => {
        this.service.generateDailyFile(
          this.props.token,
          this.state.chooseStartDate ? this.state.startDate : null,
          this.state.endDate,
          this.setState.bind(this),
          this.setMessages.bind(this),
          this.downloadPDF.bind(this)
        );
      }
    );
  };

  downloadPDF = (data, name) => {
    utils.generatePDF(name, data, this.setState.bind(this));
  };

  checkChooseStartDate = () => {
    this.setState(
      {
        chooseStartDate: !this.state.chooseStartDate,
      },
      () => {
        if (!this.state.chooseStartDate) {
          this.setState({
            startDate: "",
          });
        }
      }
    );
  };

  render() {
    return (
      <React.Fragment>
        <div class="company-daily-statistics-div">
          <div class="section">
            <div class="left-input content-container">
              {" "}
              <div class="div-parent-flex-row mb-3">
                <Form.Group className="div-50-son">
                  <Form.Label className="input-label-inside-div">
                    {this.props.localizedText["start-date"]}
                  </Form.Label>
                  <DatePicker
                    className={
                      !this.state.chooseStartDate || this.state.loadingDownload
                        ? "date-picker-report disabled"
                        : "date-picker-report"
                    }
                    showIcon
                    onChange={(date) => this.setStartDate(date)}
                    selected={
                      this.state.chooseStartDate
                        ? Date.parse(this.state.startDate)
                        : Date.parse("07/31/2023")
                    }
                    icon={<FaCalendar className="calendar-icon" />}
                    disabled={
                      !this.state.chooseStartDate || this.state.loadingDownload
                    }
                  />
                </Form.Group>

                <Form.Group className="div-50-son">
                  <Form.Label className="input-label-inside-div">
                    {this.props.localizedText["end-date"]}
                  </Form.Label>
                  <DatePicker
                    className={
                      this.state.loadingDownload
                        ? "date-picker-report disabled"
                        : "date-picker-report"
                    }
                    showIcon
                    onChange={(date) => this.setEndDate(date)}
                    selected={Date.parse(this.state.endDate)}
                    icon={<FaCalendar className="calendar-icon" />}
                    disabled={this.state.loadingDownload}
                  />
                </Form.Group>
              </div>{" "}
              <Form.Check
                label={this.props.localizedText["choose-start-date"]}
                type="checkbox"
                onClick={this.checkChooseStartDate}
                className="choose-start-date-check"
                checked={this.state.chooseStartDate}
              />
              <Button
                className="inside-primary-button generate-pdf-buton"
                disabled={
                  this.state.loadingDownload ||
                  this.state.endDate == "" ||
                  (this.state.chooseStartDate && this.state.startDate == "")
                }
                onClick={this.generateDailyFile}
              >
                {!this.state.loadingDownload &&
                this.state.pdfGeneratedSuccessfully == null ? (
                  <MdOutlinePictureAsPdf className="loader-send-inside-div-button" />
                ) : null}
                {this.state.loadingDownload ? (
                  <Spinner className="loader-send-inside-div-button"></Spinner>
                ) : null}
                {this.state.pdfGeneratedSuccessfully != null &&
                this.state.pdfGeneratedSuccessfully ? (
                  <FaCheck className="loader-send-inside-div-button" />
                ) : null}

                {this.props.localizedText["generate-and-save"]}
              </Button>
            </div>
            <div class="right-div">
              <div class="value-input">
                {this.state.loadingDownload ? (
                  <div class="download-result fade-in-up">
                    <Spinner className="icon" />
                    <div class="info">
                      {this.props.localizedText["download-is-being-processed"]}
                    </div>
                  </div>
                ) : null}
                {!this.state.loadingDownload &&
                this.state.pdfGeneratedSuccessfully != null &&
                this.state.pdfGeneratedSuccessfully ? (
                  <div class="download-result fade-in-up">
                    <FaCheck className="icon" />
                    <div class="info">
                      {" "}
                      {this.props.localizedText["your-download-was-sucessful"]}
                    </div>
                    <div
                      class="download-link"
                      onClick={() => {
                        this.downloadPDF(
                          this.state.pdfGenerated.data,
                          "ds" + "-" + this.state.pdfGenerated.endDate
                        );
                      }}
                    >
                      {this.state.pdfGenerated.name}
                    </div>
                    <div class="bottom-info">
                      {
                        this.props.localizedText[
                          "if-your-download-didnt-start-you-can-manually"
                        ]
                      }
                    </div>
                  </div>
                ) : null}{" "}
                {!this.state.loadingDownload &&
                this.state.pdfGeneratedSuccessfully != null &&
                !this.state.pdfGeneratedSuccessfully ? (
                  <div class="download-result">
                    <MdOutlineError className="icon" />
                    <div class="info">
                      {
                        this.props.localizedText[
                          "there-was-a-problem-processing-your-file"
                        ]
                      }
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>{" "}
          <ErrorMessageComponent
            errorMessage={this.props.localizedText[this.state.errorMessageType]}
          ></ErrorMessageComponent>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(CompanyDailyStatistics);
