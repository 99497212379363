import { portfolioAPI } from "../../apis/PortfolioAPI.jsx";

export default class MonthlyPaymentReportService {
  generateMonthlyReport(
    token,
    startDate,
    endDate,
    format,
    setState,
    setMessages,
    downloadPDF
  ) {
    portfolioAPI
      .generateMonthlyReport(token, startDate, endDate, format)
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("generateMonthlyReport success", response);

          if (format == "HTML") {
            setState({
              htmlData: response.data,
              loadingGenerate: false,
            });
          } else {
            setState({
              loadingGenerate: false,
              pdfGenerated: {
                name:
                  "monthly-payment-report-" +
                  startDate +
                  "-" +
                  endDate +
                  ".pdf",
                data: response.data,
              },
            });
            downloadPDF(
              response.data,
              "monthly-payment-report-" + startDate + "-" + endDate
            );
          }
        } else {
          console.log("generateMonthlyReport failure", response);
          setState({
            loadingGenerate: false,
          });
        }
      })
      .catch((error) => {
        console.log("generateMonthlyReport error", error);
        setState({ loadingGenerate: false });
      });
  }
}
